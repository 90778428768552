@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-notifications-component/dist/theme.css";

@font-face {
  font-family: akrobat;
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.cdnfonts.com/s/14486/Akrobat-Regular.woff)
    format("woff");
}
@font-face {
  font-family: akrobat light;
  font-style: normal;
  font-weight: 400;
  src:
    local("Akrobat Light"),
    url(https://fonts.cdnfonts.com/s/14486/Akrobat-Light.woff) format("woff");
}
@font-face {
  font-family: akrobat extralight;
  font-style: normal;
  font-weight: 300;
  src:
    local("Akrobat ExtraLight"),
    url(https://fonts.cdnfonts.com/s/14486/Akrobat-ExtraLight.woff)
      format("woff");
}
@font-face {
  font-family: akrobat semibold;
  font-style: normal;
  font-weight: 600;
  src:
    local("Akrobat SemiBold"),
    url(https://fonts.cdnfonts.com/s/14486/Akrobat-SemiBold.woff) format("woff");
}
@font-face {
  font-family: akrobat bold;
  font-style: normal;
  font-weight: 700;
  src:
    local("Akrobat Bold"),
    url(https://fonts.cdnfonts.com/s/14486/Akrobat-Bold.woff) format("woff");
}
@font-face {
  font-family: akrobat extrabold;
  font-style: normal;
  font-weight: 800;
  src:
    local("Akrobat ExtraBold"),
    url(https://fonts.cdnfonts.com/s/14486/akrobat-extrabold-webfont.woff)
      format("woff");
}
@font-face {
  font-family: akrobat black;
  font-style: normal;
  font-weight: 900;
  src:
    local("Akrobat Black"),
    url(https://fonts.cdnfonts.com/s/14486/Akrobat-Black.woff) format("woff");
}

.leaflet-control {
  z-index: 0 !important;
}
.leaflet-pane {
  z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border: 4px solid #a89789;
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  overflow: hidden;
  aspect-ratio: 1;
  object-position: center center;
}

.image-gallery-image {
  object-position: center center;
  object-fit: cover !important;
  width: 100%;
  aspect-ratio: 0.75;
}

.rnc__notification-item--default {
  background-color: #a89789;
  border: none;
}
